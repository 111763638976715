<template>
  <TheWrapper>
    <form
      novalidate="novalidate"
      class="wrapper__container refund"
      @submit.prevent="confirm()"
    >
      <h2 class="step__main-title">
        {{ $t('Edit address') }}
      </h2>
      <div class="address-line__entry address-line__name">
        <label
          class="address__label"
          for="name"
        >{{ $t('Name') }}</label>
        <input
          id="name"
          v-model="v$.name.$model"
          class="address__input"
          :class="{ 'address__input--error': v$.name.$error }"
          type="text"
          name="name"
          required
        >
        <p
          v-if="v$.name.$error"
          class="field-error"
        >
          {{ $t('Name is a required field') }}
        </p>
      </div>
      <fieldset class="address-line">
        <div class="address-line__entry address-line__street">
          <label
            class="address__label"
            for="street"
          >{{ $t('Street') }}</label>
          <input
            id="street"
            v-model="v$.street.$model"
            class="address__input"
            :class="{ 'address__input--error': v$.street.$error }"
            type="text"
            name="address"
            required
          >
          <p
            v-if="v$.street.$error"
            class="field-error field-error--mobile"
          >
            {{ $t('Street is a required field') }}
          </p>
        </div>
        <div class="address-line__entry address-line__house-number">
          <label
            class="address__label"
            for="house_number"
          >{{ $t('House number') }}</label>
          <input
            id="house_number"
            v-model="v$.houseNumber.$model"
            class="address__input"
            :class="{ 'address__input--error': v$.houseNumber.$error }"
            type="text"
            name="house_number"
            required
          >
          <p
            v-if="v$.houseNumber.$error"
            class="field-error field-error--mobile"
          >
            {{ $t('House number is a required field') }}
          </p>
        </div>
        <div
          v-if="v$.street.$error || v$.houseNumber.$error"
          class="address-line__errors"
        >
          <p
            v-if="v$.street.$error"
            class="field-error"
          >
            {{ $t('Street is a required field') }}
          </p>
          <p
            v-if="v$.houseNumber.$error"
            class="field-error"
          >
            {{ $t('House number is a required field') }}
          </p>
        </div>
        <div class="address-line__entry address-line__address2">
          <label
            class="address__label"
            for="address2"
          >{{ $t('Address 2') }}
            <span class="ui-text:normal">{{ $t('(optional)') }}</span>
          </label>
          <input
            id="address2"
            v-model="address2"
            class="address__input"
            type="text"
            name="address"
          >
        </div>
      </fieldset>
      <div class="address__entry">
        <label
          class="address__label"
          for="post_code"
        >{{ $t('Postal code') }}</label>
        <input
          id="post_code"
          v-model="v$.postalCode.$model"
          class="address__input"
          type="text"
          name="post_code"
          required
        >
        <p
          v-if="v$.postalCode.$error"
          class="field-error"
        >
          {{ $t('Postal code is a required field') }}
        </p>
      </div>
      <div class="address__entry">
        <label
          class="address__label"
          for="city"
        >{{ $t('City') }}</label>
        <input
          id="city"
          v-model="v$.city.$model"
          class="address__input"
          type="text"
          name="city"
          required
        >
        <p
          v-if="v$.city.$error"
          class="field-error"
        >
          {{ $t('City is a required field') }}
        </p>
      </div>

      <div class="address__entry">
        <label
          class="address__label"
          for="country"
        >{{ $t('Country') }}</label>
        <span
          class="address__input address__input--disabled"
          name="country"
        >{{ countryName }}</span>
      </div>

      <UiButton
        mode="primary"
        class="main-button"
      >
        <button
          class="main-button__btn"
          :style="brandStyle"
          :disabled="disableNext"
        >
          <span>{{ $t('Apply') }}</span>
          <FontAwesomeIcon :icon="$options.faChevronRight" />
        </button>
      </UiButton>
    </form>
  </TheWrapper>
</template>
<script>
import { APP_SET_PARCEL } from '../store/mutation.types'
import { brandColourMixin } from '@/components/mixins'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import {
  faChevronRight,
} from '@fortawesome/free-solid-svg-icons'
import { handleRouteRejection } from '@/utils'
import { mapState } from 'vuex'
import { UiButton } from '@sendcloud/ui-library'
import TheWrapper from '@/components/layout/TheWrapper'

import useVuelidate from '@vuelidate/core'

import { required } from '@vuelidate/validators'

export default {
  name: 'ReturnsEditAddress',
  faChevronRight,
  components: {
    FontAwesomeIcon,
    UiButton,
    TheWrapper,
  },
  mixins: [brandColourMixin],
  setup() {
    return { v$: useVuelidate() }
  },
  data() {
    return {
      street: '',
      postalCode: '',
      city: '',
      houseNumber: '',
      name: '',
      address2: '',
    }
  },
  computed: {
    ...mapState({
      parcel: (state) => state.parcel,
    }),
    countryName() {
      if (!this.parcel || !this.parcel.country) return ''
      return this.parcel.country.name || ''
    },
    disableNext() {
      return this.v$.$invalid
    },
  },
  validations: {
    street: { required },
    postalCode: { required },
    city: { required },
    houseNumber: {},
    name: { required },
  },
  created() {
    const { street, house_number: houseNumber } = this.parcel.address_divided
    this.street = street
    this.houseNumber = houseNumber
    this.postalCode = this.parcel.postal_code
    this.city = this.parcel.city
    this.name = this.parcel.name
    this.address2 = this.parcel.address_2
  },
  methods: {
    confirm() {
      if (this.v$.$invalid) return

      this.$store.commit(APP_SET_PARCEL, {
        ...this.parcel,
        address: this.street,
        address_divided: {
          street: this.street,
          house_number: this.houseNumber,
        },
        postal_code: this.postalCode,
        city: this.city,
        name: this.name,
        address_2: this.address2,
      })
      this.$router.push({ name: 'summary' }).catch(handleRouteRejection)
    },
  },
}
</script>
<style lang="scss" scoped>
@import '@/assets/styles/_variables.scss';
.address-line {
  display: flex;
  border: none;
  padding: 0;
  flex-direction: column;
  flex-wrap: wrap;

  @media (min-width: 37.5rem) {
    justify-content: space-between;
    flex-direction: row;
  }
}
.address-line__entry {
  display: flex;
  flex-direction: column;
  width: 100%;
}
.address-line__house-number {
  @media (min-width: 37.5rem) {
    width: 28%;
  }
}
.address-line__street {
  @media (min-width: 37.5rem) {
    width: 68%;
  }
}
.address-line__errors {
  width: 100%;
  display: none;

  @media (min-width: 37.5rem) {
    display: block;
  }
}
.address__entry {
  display: flex;
  flex-direction: column;
}
.address__label {
  margin: 0.75rem 0 0.625rem;
  font-weight: 700;

  @media (min-width: 37.5rem) {
    margin-top: 2.25rem;
  }
}
.address__input {
  border: $default-border;
  border-radius: $default-border-radius;
  padding: 1rem;

  &--error {
    border-color: #cc0022;
  }

  &:focus {
    box-shadow: $focus-shadow;
  }
}
.address__input--disabled {
  color: #c3c5c7;
  border-color: #c3c5c7;
}
</style>
